import { CSSObject } from '@emotion/core';
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import { ChangeEvent, HTMLProps, useEffect, useState } from 'react';

import Legend from '@components/controls/Legend';

import { Layout, VisuallyHidden, colors, scale } from '@scripts/gds';

import MinusIcon from '@icons/24/minus.svg';
import PlusIcon from '@icons/24/plus.svg';

export interface CounterProps extends Omit<HTMLProps<HTMLDivElement>, 'onChange'> {
    /** Input unique name. Used for name and id properties */
    name: string;
    /** Initial input value */
    initialValue?: number;
    /** Input value */
    value?: number;
    /** Label text */
    label: string;
    /** Minimum value */
    min?: number;
    /** Maximum value */
    max?: number;
    /** Step value */
    step?: number;
    /** Handler change event on input */
    onChange?: (value: number) => void;
    /** Visually hidden legend */
    isHiddenLegend?: boolean;
    /** Flag to change view counter */
    vertical?: boolean;
    /** Required field */
    required?: boolean;
    /** Hint text */
    hint?: string;
    /** Formik field object (inner) */
    field?: FieldInputProps<number>;
    /** Formik meta object (inner) */
    meta?: FieldMetaProps<any>;
    /** Formik helpers object (inner) */
    helpers?: FieldHelperProps<number>;
    /** Special styles for buy button */
    isBuyBtn?: boolean;
}

const Counter = ({
    isBuyBtn,
    name,
    initialValue = 1,
    value,
    label,
    step = 1,
    min = 0,
    max = 999,
    onChange,
    isHiddenLegend = true,
    vertical = false,
    required,
    hint,
    field,
    meta,
    helpers,
    disabled = false,
    ...props
}: CounterProps) => {
    const [innerValue, setInnerValue] = useState<string | number>(initialValue);

    useEffect(() => {
        if (value) setInnerValue(value);
    }, [value, innerValue]);

    const changeValue = (newValue: number) => {
        setInnerValue(newValue);
        if (onChange) onChange(newValue);
        if (helpers) helpers.setValue(newValue);
    };

    const handleInputBlur = ({ target }: ChangeEvent<HTMLInputElement>) => {
        const newValue = +target.value;
        if (newValue < min) {
            changeValue(min);
            return;
        }
        if (newValue > max) {
            changeValue(max);
            return;
        }
        changeValue(newValue);
    };

    const buttonCSS: CSSObject = {
        backgroundColor: colors.primary,
        width: scale(3),
        height: scale(3),
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ':disabled': { backgroundColor: colors.textNuanceLight, cursor: 'not-allowed' },
    };

    const iconCSS: CSSObject = {
        verticalAlign: 'middle',
        fill: 'currentColor',
        transition: `fill ease 300ms`,
        width: scale(3, true),
    };

    return (
        <div {...props}>
            {isHiddenLegend ? (
                <VisuallyHidden>
                    <Legend name={name} label={label} required={required} hint={hint} meta={meta} />
                </VisuallyHidden>
            ) : (
                <Legend name={name} label={label} required={required} hint={hint} meta={meta} />
            )}
            <Layout
                areas={
                    vertical
                        ? ['input button-increase', 'input button-decrease']
                        : 'button-decrease input button-increase'
                }
                gap={0}
                inline
            >
                <Layout.Item area="button-decrease" align="center">
                    <button
                        type="button"
                        onClick={() => changeValue(Number(innerValue) - step)}
                        disabled={Number(innerValue) < min + step || disabled}
                        title={`Уменьшить на ${step}`}
                        css={{
                            ...buttonCSS,
                            stroke: colors.white,
                            ...(vertical && { borderTop: 'none' }),
                            ...(isBuyBtn && {
                                backgroundColor: '#ffffff',
                                stroke: colors.primary,
                                width: scale(3),
                                height: scale(3),
                            }),
                        }}
                    >
                        <MinusIcon css={iconCSS} />
                    </button>
                </Layout.Item>
                <Layout.Item area="input" css={{ display: 'flex', alignItems: 'center' }}>
                    <input
                        type="number"
                        name={name}
                        id={name}
                        {...field}
                        value={innerValue}
                        step={step}
                        onChange={({ target }) =>
                            target.value ? changeValue(Number(target.value)) : setInnerValue('')
                        }
                        onBlur={handleInputBlur}
                        onClick={({ currentTarget }) => currentTarget.select()}
                        css={{
                            width: scale(8),
                            height: vertical ? '100%' : isBuyBtn ? scale(5, true) : scale(1, false, 29),
                            padding: `0 ${scale(1)}px`,
                            border: 'none',
                            textAlign: 'center',
                            fontSize: isBuyBtn ? '1.25rem' : '1.5rem',
                            fontWeight: 500,
                            ...(isBuyBtn && { backgroundColor: 'transparent', color: colors.white }),
                            ...(vertical && { borderLeft: `1px solid colors?.black` }),
                            ':focus': { outlineOffset: -2, outline: 'none' },
                            '::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
                                display: 'none',
                            },
                        }}
                    />
                </Layout.Item>
                <Layout.Item area="button-increase" align="center">
                    <button
                        type="button"
                        onClick={() => changeValue(Number(innerValue) + step)}
                        disabled={Number(innerValue) > max - step || disabled}
                        title={`Увеличить на ${step}`}
                        css={{
                            ...buttonCSS,
                            stroke: colors.white,
                            ...(isBuyBtn && {
                                backgroundColor: colors.white,
                                stroke: colors.primary,
                                width: scale(3),
                                height: scale(3),
                            }),
                        }}
                    >
                        <PlusIcon css={iconCSS} />
                    </button>
                </Layout.Item>
            </Layout>
        </div>
    );
};

export default Counter;
